.accordionItemWrapper {
    margin-bottom: 10px;
    z-index: 2;

    .accordionItem {
        border-radius: 7px;

        .chevron-icon {
            transform: rotateX(0);
            transition: transform .15s ease-in;
        }

        &.isOpen {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            .chevron-icon {
                transform: rotateX(180deg);
                transition: transform .15s ease-in;
            }
        }

        ion-icon {
            &.danger {
                color: var(--ion-color-danger);
            }

            &.read {
                color: var(--ion-color-light-shade);
            }
        }
    
        h2.title {
            font-weight: bold;
            white-space: normal;
    
            &.danger {
                color: var(--ion-color-danger);
            }
    
            &.read {
                color: var(--ion-color-secondary);
            }
        }
    
        p {
            color: var(--ion-color-secondary);
        }

        ion-chip {
            font-size: 11px;
            height: 26px;
        }
    }

    .subItem {
        background: #ffffff;
        padding: 0;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s ease-in;

        &.isOpen {
            max-height: max-content;
            transition: max-height .3s ease-in;
        }

        .subItem-inner {
            border-top: 1px solid rgba(46, 66, 127, .15);
            padding: 20px;
            color: var(--ion-color-secondary);
            font-size: 14px;
            line-height: 20px;

            p {
                margin: 0;
            }
        }
    }
}

.pullToRefresh {
    height: 300px;
}
