.public-invoices {
  #myPatent-Header {
    display: none !important;
  }
  .desktopLogo {
    display: block !important;
    margin-bottom: 20px;
  }

  #ionGridInner {
    padding: 20px;
  }
}
