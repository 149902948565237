a {
    text-decoration: none;
}

ion-content {
    --background: transparent;
    background: url('./assets/images/bg-pattern.png') repeat;
}

#isDev {
    position: absolute;
    z-index: 99;
    text-transform: uppercase;
    bottom: 0;
    background: var(--ion-color-primary);
    width: 100%;
    text-align: center;
    font-size: 12px;
    padding: 5px 0;
}

.alert-button.sc-ion-alert-ios:last-child {
    font-weight: normal;
}

.buttonHolder {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: center;

    ion-button {
        display: flex;
        flex: 1 1;
    }
}

ion-grid {
    padding: 0 30px;
}

.errorsList {
    p {
        margin-top: 0;
    }
}

.rounded-input {
    --border-color: var(--ion-color-secondary);
    --color: var(--ion-color-secondary);
    --border-radius: 2pt;
    --border-width: 1px;
    --highlight-height: 0;
    --background: var(--ion-item-background);
    --min-height: 60px;
    margin-bottom: 20px;

    .sc-ion-label-ios-h {
        -webkit-transform: translate3d(0, 13px, 0);
        transform: translate3d(0, 13px, 0);
    }

    &.item-has-value,
    &.item-has-focus {
        --color: var(--ion-color-primary);

        ion-label {
            color: var(--ion-color-secondary);
        }
    }

    &.error {
        --border-color: var(--ion-color-danger);
        --color: var(--ion-color-danger);
    }
}

.checkbox-input {
    font-size: 14px;
    
    &.error {
        ion-label {
            --color: var(--ion-color-danger) !important;
        }
    }
}

#mainSplitPane {
    --border: 0;
    --side-max-width: 300px;
}

.list-between {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    margin-bottom: 5px;
    color: var(--ion-color-secondary);
    font-size: 16px;
    line-height: 20px;

    span:last-of-type {
        font-weight: bold;
    }

    & > span {
        display: flex;
        padding: 15px 20px;
        background: var(--ion-item-background);
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        word-break: break-word;
        align-items: center;
        border-top: 1px solid var(--ion-item-selected-background);
        border-left: 0;
        border-bottom: 1px solid var(--ion-item-selected-background);
        border-right: 1px solid var(--ion-item-selected-background);
        text-align: left;
        
        &:first-of-type {
            background: var(--ion-item-selected-background);
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-left: 1px solid var(--ion-item-selected-background);
            border-right: 0;
        }
    }

    &.price {
        & > span {
            background: var(--ion-color-primary-shade);
            border-color: var(--ion-color-primary);
            color: #fff;

            &:first-of-type {
                color: #fff;
                background: var(--ion-color-primary);
                border-color: var(--ion-color-primary);
                font-weight: 600;
            }
        }
    }
}

ion-card {
    margin: 0;
    border-radius: 6px;

    &.fixedBottom {
        left: 0;
        position: fixed;
        bottom: 0;
        margin: 30px;
    }

    &.adsCard {
        height: 167px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &.alertCard {
        overflow: initial;
        padding-top: 20px;
        margin-top: 40px;
    
        .alertIcon {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);
            z-index: 1024;
            height: 60px;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            background: #101214;
            color: var(--ion-color-primary);
            border-radius: 50%;
    
            &::after {
                content: '';
                width: 30px;
                height: 30px;
                background: transparent;
                position: absolute;
                border-radius: 50%;
                border: 5px solid var(--ion-color-primary);
            }
        }

        ion-card-content {
            font-size: 14px;
            text-align: center;
        }
}}

ion-button {
    --border-radius: 6px;
    font-weight: 600;
}

ion-refresher {
    z-index: 1;
}

.filterSelect {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 20px;
}

div.filterModalToolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    border-radius: 7px;
    
    span {
        color: var(--ion-color-dark);
        font-size: 14px;
    }
}

div.hasButton {
    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
    padding: 0;

    h1 {
        margin: 0;
    }
}

.popover-wrapper.sc-ion-popover-ios {
    --max-width: 350px;
    --min-width: 350px;
}

div.emptyText {
    text-align: center;
    margin-top: 50px;

    img {
        width: 200px;
        margin-bottom: 30px;
    }
    
    h6 {
        color: var(--ion-color-dark);
        font-weight: 600;
        font-size: 20px;
    }

    p {
        color: var(--ion-color-medium);
        font-size: 15px;
    }
}

.fixedFab {
    position: fixed;
    
    &.fab-horizontal-start {
        left: var(--ion-padding, 16px);
    }

    &.fab-horizontal-end {
        right: var(--ion-padding, 16px);
    }
}

.rightButton {
    display: flex;
    flex-flow: row-reverse;
}

.refresher-pulling-icon,
ion-refresher ion-spinner {
    background: #fff;
    border-radius: 50%;
    border: 3px solid #fff;
    width: 38px;
    height: 38px;
    color: var(--ion-color-primary);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.refresher-ready ion-refresher-content .refresher-pulling,
.refresher-cancelling ion-refresher-content .refresher-pulling,
.refresher-pulling ion-refresher-content .refresher-pulling {
    display: flex;
    justify-content: center;
    align-items: center;
    
    ion-icon {
        color: var(--ion-color-primary);
        font-size: 20px;
    }
}

h1.pageTitle {
    text-align: left;
    font-size: 32px;
    line-height: 43px;
    font-weight: 600;
    margin: 0 0 20px 0;
    color: var(--ion-color-secondary);
}

h1.alertIcon {
    font-size: 120px;
    margin: 0;
}

h2.alertText {
    font-size: 28px;
    font-weight: 600;
    margin: 0 0 30px;
}

h3.smallTitle {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    margin: 0 0 16px 0;
    color: var(--ion-color-secondary); 
}

.numberPicker {
    display: flex;
    max-width: 110px;
    background: #101214;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 0px 4px;

    ion-button {
        --padding-start: 4px;
        --padding-end: 4px;
    }

    input,
    ion-input {
        text-align: center !important;
        padding: 0 !important;
    }
}

#ionGridInner {
    padding-top: 120px;
}

ion-row {
    margin: 0 -5px;
}

#myPatent-Header {
    position: fixed;
    left: 0;
    top: 0;
    display: block;
    height: 100px;
    width: 100%;
    padding: 0 25px 0 15px;
    z-index: 999;
    pointer-events: none;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    background: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;

    #myPatent-Header-wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-inline-start: var(--ion-padding, 16px);
        padding-inline-end: var(--ion-padding, 16px);
        transition: all 0.25s ease;
        box-shadow: none;
        
        ion-router-link,
        ion-menu-button {
            z-index: 2;
            pointer-events: all;
        }

        .logoDiv {
            display: flex;
            flex-flow: row;
            max-width: 75%;
            align-items: center;
            justify-content: flex-end;

            img {
                pointer-events: none;
                opacity: 1;
                width: 13em;
                transition: opacity 0.15s ease;
        
                /*&.hidden {
                    opacity: 0;
                    transition: opacity 0.15s ease;
                }*/
            }

            span {
                display: block;
                text-align: right;
                font-size: 14px;
                font-weight: 600;
            }
        }


        &.hidden {
            background: rgba(255, 255, 255, 1);
            transition: all 0.25s ease;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        }
    }

    /*&.hasMenu::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 190px;
        height: 130px;
        background: var(--ion-color-secondary);
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        z-index: 1;
    }*/
}

span#copyright {
    position: relative;
    text-align: center;
    font-size: 12px;
    width: 100%;
    display: block;
}

#appDownload {
    display: none;
}

.inputWithModalWrapper {
    margin: 0 0 10px;

    .inputWithModal {
        border-radius: 7px;

        .datamodValue {
            margin-right: 5px;
        }

        del {
            margin-top: 3px;
            font-size: 14px;
        }
    }
}

.stickyButton {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    padding: 12px;
    height: auto;
    background: var(--ion-item-background);
    box-shadow: 0 -20px 25px -5px rgba(0, 0, 0, 0.1), 0 -10px 10px -5px rgba(0, 0, 0, 0.04);
    z-index: 50;
}

.inputWithModalWrapper-modal {
    .modal-wrapper {
        border-radius: 6px;
        height: 172px;
        min-width: 310px;
        max-width: 90%;
    }
}

.smsText {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 25px 0 0 0;
    font-size: 22px;
    color: var(--ion-color-primary);
}

.hideOnMobile {
    display: none !important;
}

#barionLogo {
    display: block;
    height: 30px;

    img {
        height: 30px;
        width: auto;
        object-fit: contain;
    }
}

ion-checkbox {
    --border-width: 2px;
    --border-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.43);
}

@media screen and (min-width: 992px) {
    #barionLogo {
        margin-left: 15px;
    }

    .hideOnMobile {
        display: flex !important;
    }

    div.smallCenterContentDesktop {
        padding: 5% 25%;
    }

    #appDownload {
        position: fixed;
        right: 0;
        top: 0;
        width: calc(100vw - 650px);
        height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        img {
            max-height: 40vh;
        }

        h4 {
            margin-bottom: 25px;
            font-size: 22px;
        }

        .downloadButtons {
            width: 530px;
            margin-bottom: 25px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;

            a {
                display: flex;

                img {
                    max-height: 65px;
                }
            }
        }

        .qr {
            width: 150px;
        }
    }

    span#copyright {
        position: fixed;
        bottom: 25px;
        right: 25px;
        font-size: 14px;
        width: auto;
        left: auto;
    }

    #myPatent-Header {
        padding: 0 45px 0 25px;
        display: none;
    }

    #ionGridInner {
        padding: 30px 17.5%;
        width: 100%;
    }

    .split-pane-main {
        h1.pageTitle {
            padding: 20px 0 15px 0;
            font-size: 46px;
        }
    }

    div.filterModalToolbar {
        padding: 0;

        span {
            font-size: 16px;
        }
    }

    div.emptyText {
        margin-top: 100px;
    }

    div.hasButton {
        justify-content: flex-start;

        h1 {
            margin-right: 15px;
        }
    }

    .list-between {
        grid-template-columns: 25% 1fr;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
    -webkit-text-fill-color: var(--ion-color-primary) !important;
    border-radius: 0 !important;
}

@media (prefers-color-scheme: dark) {
    ion-content {
        --background: transparent;
        background: var(--ion-background-color);
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px var(--ion-item-selected-background) inset !important;
        -webkit-text-fill-color: var(--ion-color-primary) !important;
    }

    h1.pageTitle {
        color: var(--ion-color-light);
    }

    .accordionItemWrapper {
        .accordionItem {
            p,
            h2 {
                color: var(--ion-color-light);

                &.title.read {
                    color: var(--ion-color-light);
                }
            }

            ion-icon.read {
                color: var(--ion-item-selected-background);
            }
        }

        .subItem {
            background: var(--ion-item-background);

            .subItem-inner { 
                color: var(--ion-color-light);
            }
        }
    }

    .list-between {
        color: var(--ion-color-light);
    }

    #desktopWelcome {
        background: rgba(0,173,238,1);
    }

    .inlineButtons {
        margin-top: 15px;
    }

    .cardButton {
        box-shadow: 0 3px 18px #0000001f;

        img {
            max-width: 120px;
            max-height: 60px;
            margin-bottom: 25px;
        }

        h2 {
            color: var(--ion-color-light); 
        }

        ion-icon {
            color: var(--ion-color-light);
        }

        &:hover {
            background: var(--ion-item-selected-background);
        }
    }

    ion-menu.ios {
        ion-item {
            --color: #66697d;

            ion-icon {
                color: #6a7097;
            }
        }

        hr.menuHr {
            background: #292c3e;
        }

        ion-item.selected,
        ion-item.menu-route:hover {
            --color: var(--ion-color-primary);
            --background: var(--ion-item-selected-background);
        }

        #menuLogoHolder {
            img {
                content: url('./assets/images/logo_dark.svg');
            }
        }
        
        #menuProfileHolder {
            h2 {
                color: var(--ion-color-primary);
            }

            p {
                color: var(--ion-color-light);
            }
        }
    }

    #myPatent-Header {
        #myPatent-Header-wrapper {
            img {
                content: url('./assets/images/logo_dark.svg');
            }

            &.hidden {
                background: rgba(27, 30, 47, 1);
            }
        }

        /*&.hasMenu::after {
            background: var(--ion-item-background);
        }*/
    }

    .rounded-input {
        --background: var(--ion-item-selected-background);
        --color: var(--ion-color-light);
        --border-color: var(--ion-color-light);

        &.item-has-value ion-label,
        &.item-has-focus ion-label {
            color: var(--ion-color-light);
        }

        input {
            background-color: transparent !important;
            color: inherit !important;
        }
    }

    div.emptyText h6 {
        color: var(--ion-color-light);
    }

    h3.smallTitle {
        color: var(--ion-color-light);
    }

    .ion-color.sc-ion-label-ios-h {
        color: var(--ion-color-light);
    }

    .desktopLogo {
        content: url('./assets/images/logo_dark.svg');
    }

    div.filterModalToolbar {
        span {
            color: var(--ion-color-light);
        }
    }
}

.react-tel-input {
    margin-bottom: 25px;
    .form-control {
        background: var(--ion-item-selected-background);
        color: var(--ion-color-primary);
        border-color: var(--ion-color-light);
        width: 100%;
        height: 68px;
        font-size: 17px;
        padding-left: 78px;
        padding-right: 20px;
    }

    .selected-flag {
        width: 58px;
        padding: 0 0 0 18px;
    }

    .country-list {
        margin: 0 0 0 -1px;

        .country {
            border-bottom: 1px solid #ccc;
            padding: 15px 15px;
            
            .country-name {
                color: #000;
            }
        }
    }
}

ion-select::part(icon) {
    opacity: 1;
}

.chevron-icon {
    position: relative;
    width: 12px;
    height: 18px;
    opacity: 1;
    
    .chevron-icon-inner {
        left: 5px;
        top: 50%;
        margin-top: -2px;
        position: absolute;
        width: 0px;
        height: 0px;
        border-top: 5px solid;
        border-right: 5px solid #0000;
        border-left: 5px solid #0000;
        color: currentcolor;
        pointer-events: none;
    }
}
