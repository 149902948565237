ion-toggle {
    &.disabled {
        pointer-events: none;
        opacity: .25;
    }
}

.cardButton {
    &.alert {
        #serviceAlert {
            border-left: 3px solid var(--ion-color-primary);
            padding: 15px;
            color: var(--ion-color-primary);
            display: flex;
            align-items: center;
            justify-content: space-between;
        
            h2 {
                margin: 0;
                font-size: 16px;
                color: var(--ion-color-primary);
            }
        }

        &.warning {
            #serviceAlert {
                border-left: 3px solid var(--ion-color-success);
                padding: 15px;
                color: var(--ion-color-success);
                display: flex;
                align-items: center;
                justify-content: space-between;
            
                h2 {
                    margin: 0;
                    font-size: 16px;
                    color: var(--ion-color-success);
                }
            }
        }
    }
}

.service-badge {
    margin-top: 7px;
    margin-right: 5px;
}
