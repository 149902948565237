.bottomMargin {
    margin-bottom: 20px;
}

.highlightedSelect {
    --background: var(--ion-color-primary);
    color: #fff;
}

.datamodListModal,
.datamodList {
    ion-card-header {
        cursor: pointer;
    }

    ion-card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;

        .chevronIcon {
            &.isClosed {
                transform: rotateX(0);
                transition: transform 0.15s ease-in;
            }

            &.isOpen {
                transform: rotateX(180deg);
                transition: transform 0.15s ease-in;
            }
        }
    }

    ion-card-content {
        max-height: 0;
        overflow: hidden;
        padding: 0;

        &.isOpen {
            max-height: max-content;
        }

        &.isClosed {
            padding: 0;
        }

        /*.datamod-item {
            --background: transparent;
            --min-height: 40px;
            height: 55px;
            border-radius: 6px;

            ion-label {
                margin: 0;
                color: var(--ion-color-tertiary) !important;
                position: absolute;
                transform: translate3d(0, 8px, 0);
                transition: all .2s ease;
            }

            &.item-has-focus ion-label {
                transform: translate3d(0, -20px, 0);
                font-size: 10px;
                transition: all .2s ease;
            }

            ion-input {
                background: var(--ion-background-color);
                border-radius: 6px;
            }


    
            ion-badge {
                font-size: 11px;
            }
    
            ion-select::part(icon) {
                display: none !important;
            }
        }*/
    }

    /*.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-ios-h {
        opacity: 1;
        // color: var(--ion-color-grey) !important;   
    }
    
    .native-input[disabled].sc-ion-input-ios {
        opacity: 1;
        // color: var(--ion-color-grey) !important;   
    }*/

    /*.item-interactive {
        &:before {
            position: relative;
            content: '';
            background: var(--ion-color-primary);
            width: 2px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 5;
        }

        &.hasChanged {
            ion-label {
                color: var(--ion-color-warning) !important;
            }

            &:before {
                background: var(--ion-color-warning);
            }
        }

        &.item-interactive-disabled {
            &:before {
                background: transparent;
            }
        }
    }*/
}

.datamod-input {
    position: relative;

    p {
        margin: 0;
    }

    .editable-icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 28px;
        height: 28px;
        background: rgba(var(--ion-color-primary-rgb), 0.35);
        z-index: 999;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
    }

    &.editable {
        &::before {
            content: "";
            position: absolute;
            top: 28px;
            bottom: 0;
            left: 0;
            background: rgba(var(--ion-color-primary-rgb), 0.35);
            width: 3px;
            z-index: 99;
        }

        ion-item-divider {
            --padding-start: 36px;
        }
    }

    &.hidden {
        display: none !important;
    }

    ion-spinner {
        height: 17px;
        width: 17px;
        margin: 5px 0 0 5px;
    }

    .original-value {
        display: flex;
        padding: 5px 20px 6px;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 13px;
        }
    }
}

ion-select.fullLength {
    min-width: 100%;
    --padding-start: 0;
}

.paddingForButton {
    padding: 25px;
}

.button-grid {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    ion-button {
        flex: 1;
    }
}

.delete-toolbar {
    --min-height: 28px;

    ion-title {
        font-size: 14px;
    }
}

.fixedFab {
    position: fixed;
}

#datamod_buttons {
    display: flex;
    flex-flow: row-reverse;
}

@media screen and (max-width: 991px) {
    #datamod_buttons {
        flex-flow: column;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #1b1e2f;
        padding: 7px;
        border-top: 2px solid #292c38;

        ion-button {
            margin: 0;
            width: 100%;

            &:first-of-type {
                margin-bottom: 7px;
            }
        }
    }

    .page-content {
        margin-bottom: 120px;
    }

    .button-grid {
        position: absolute;
    }
}
