ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

.menu-content-open {
    pointer-events: all !important;
}

ion-menu.ios {
    #menuLogoHolder {
        text-align: center;
        margin: 35px 0 20px;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
    }

    hr.menuHr {
        background: #e2e2e2;
    }

    #menuProfileHolder {
        margin: 0 0 5px;

        h2 {
            font-weight: bold;
        }
    }

    #menuSelectHolder {
        font-weight: 400;
        margin-bottom: 0;
        border-bottom: 1px solid #e6e6e6;

        ion-select.invoiceSelect {
            max-width: 100%;
            margin: 0;
            padding: 10px 5px;
            color: var(--ion-color-primary);
            font-weight: 600;
        }
    }

    ion-note {
        line-height: 24px;
        margin-bottom: 20px;
    }

    ion-item {
        --padding-start: 16px;
        --padding-end: 16px;
        --min-height: 50px;
        --color: #696A71;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;

        ion-icon {
            font-size: 24px;
            color: #696A71;
        }

        &.menu-route:hover,
        &.selected {
            --color: var(--ion-color-primary);
            --background: #e6f5fb;

            ion-icon {
                color: var(--ion-color-primary);
            }
        }

        &.version-info {
            p {
                text-align: center;
                width: 100%;
                font-size: 10px;
                margin: 0;
            }
        }
    }

    ion-list#labels-list ion-list-header {
        margin-bottom: 8px;
    }

    ion-list-header,
    ion-note {
        padding-left: 16px;
        padding-right: 16px;
    }

    ion-note {
        margin-bottom: 8px;
    }
}

ion-note {
    display: inline-block;
    font-size: 16px;
    color: var(--ion-color-medium-shade);
}

@media screen and (min-width: 992px) {
	ion-menu.ios {
		#menuLogoHolder {
            margin: 40px 0 20px;
            
            img {
                width: 200px;
            }
        }

		#menuProfileHolder {
			margin: 0 20px 20px;
			text-align: center;

			h2 {
				color: var(--ion-color-primary);
			}

			p {
				font-size: 13px;
			}
		}

		ion-item {
			margin: 0 20px 7px;
			border-radius: 7px;
		}
	}
}
