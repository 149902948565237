@media screen and (min-width: 992px) {
    #desktopWelcome.notifications {
        position: relative;
        display: flex;
        height: 170px;
        width: 100%;
        flex-flow: column;
        background: var(--ion-color-success);
        border-radius: 14px;
        margin-bottom: 20px;
        align-items: flex-start;
        justify-content: center;
        padding: 0 30px;

        &::after {
            content: '';
            background: url('../../assets/images/bell.png') 100% 100% no-repeat;
            background-size: contain;
            position: absolute;
            right: 40px;
            bottom: 30px;
            width: 170px;
            height: 170px;
            transform: rotate(15deg);
        }
    }
}
