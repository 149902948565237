/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    src: url('../assets/fonts/Roboto-Bold.ttf');
}

* {
    font-family: 'Roboto', sans-serif !important;
}

.la,
.las {
    font-family: 'Line Awesome Free' !important;
}

:root {
    --ion-color-primary: #00ADEE;
    --ion-color-primary-rgb: 0, 173, 238;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #0098d1;
    --ion-color-primary-tint: #1ab5f0;

    --ion-color-secondary: #2E427F;
    --ion-color-secondary-rgb: 46, 66, 127;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #283a70;
    --ion-color-secondary-tint: #43558c;

    --ion-color-tertiary: #009BD8;
    --ion-color-tertiary-rgb: 0, 155, 216;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #0088be;
    --ion-color-tertiary-tint: #1aa5dc;

    --ion-color-success: #11A8AB;
    --ion-color-success-rgb: 17, 168, 171;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0f9496;
    --ion-color-success-tint: #29b1b3;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #E64C65;
    --ion-color-danger-rgb: 230, 76, 101;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #ca4359;
    --ion-color-danger-tint: #e95e74;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-grey: #383838;
}

.ios body {
    --ion-item-background: #fff;
    --ion-item-selected-background: #d2d2d2;
}


@media(prefers-color-scheme: dark){
    :root {
        --ion-color-warning: #ffc409;
        --ion-color-warning-rgb: 255,196,9;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0,0,0;
        --ion-color-warning-shade: #e0ac08;
        --ion-color-warning-tint: #ffca22;
      
        --ion-color-danger: #eb445a;
        --ion-color-danger-rgb: 235,68,90;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255,255,255;
        --ion-color-danger-shade: #cf3c4f;
        --ion-color-danger-tint: #ed576b;

        --ion-color-medium: #444860;
        --ion-color-medium-rgb: 68,72,96;
        --ion-color-medium-contrast: #ffffff;
        --ion-color-medium-contrast-rgb: 255,255,255;
        --ion-color-medium-shade: #3c3f54;
        --ion-color-medium-tint: #575a70;
    }

    .ios body {
        --ion-background-color: #111422;
        --ion-background-color-rgb: 17, 20, 34;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-color-step-50: #1d202d;
        --ion-color-step-100: #292c38;
        --ion-color-step-150: #353743;
        --ion-color-step-200: #41434e;
        --ion-color-step-250: #4d4f59;
        --ion-color-step-300: #585b64;
        --ion-color-step-350: #64666f;
        --ion-color-step-400: #70727a;
        --ion-color-step-450: #7c7e85;
        --ion-color-step-500: #888a91;
        --ion-color-step-550: #94959c;
        --ion-color-step-600: #a0a1a7;
        --ion-color-step-650: #acadb2;
        --ion-color-step-700: #b8b9bd;
        --ion-color-step-750: #c4c4c8;
        --ion-color-step-800: #cfd0d3;
        --ion-color-step-850: #dbdcde;
        --ion-color-step-900: #e7e8e9;
        --ion-color-step-950: #f3f3f4;

        --ion-toolbar-background: #1b1e2f;

        --ion-item-background: #1b1e2f;
        --ion-item-selected-background: #23273e;

        --ion-color-grey: #fff;
    }
}
